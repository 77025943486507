/* src/components/Footer.css */
.footer {
  background-color: #282c34;
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;
  width: 100%;
}

.footer-section h3 {
  margin-top: 0;
}

.footer-section p {
  margin: 5px 0;
}

.footer-section a {
  color: #61dafb;
  text-decoration: none;
}

.footer-section a:hover {
  text-decoration: underline;
}
