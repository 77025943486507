@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

/* src/App.css */
.App {
    text-align: center;
    padding-bottom: 60px;
    background: #FFA500;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

main {
    padding: 20px;
    flex: 1;
}

/* src/App.css */
body {
    font-family: 'Roboto', sans-serif;
}

.navigation-buttons {
    display: flex;
    justify-content: center;
    margin: 20px 0;
}

.navigation-buttons button {
    margin: 0 10px;
    padding: 10px 20px;
    font-size: 1em;
    cursor: pointer;
    background-color: #FFA500;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.navigation-buttons button:hover {
    background-color: #0056b3;
}

/* Header styling */
.app-header {
    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Logo styling */
.logo {
    max-width: 100%;
    height: auto;
}

/* Media query for smaller screens (phones) */
@media (max-width: 800px) {
    .navigation-buttons {
        flex-direction: column;
    }

    .navigation-buttons button {
        margin: 10px 0;
    }
}

/* src/components/ContactForm.css */
.contact-form-container {
    padding: 20px;
    background-color: #FFA500;
    border-radius: 8px;
    max-width: 600px;
    margin: 0 auto;
}

.contact-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.contact-form label {
    display: flex;
    flex-direction: column;
    font-size: 1.2em;
}

.contact-form input,
.contact-form textarea {
    padding: 10px;
    font-size: 1em;
    border-radius: 4px;
    border: 1px solid #ccc;
}

.contact-form button {
    padding: 10px 15px;
    font-size: 1em;
    color: #fff;
    background-color: #0056b3;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.contact-form button:hover {
    background-color: #0056b3;
}

.form-status {
    margin-top: 20px;
    font-size: 1.2em;
    color: green;
}

.contact-details {
    margin-top: 20px;
    font-size: 1.2em;
}

.contact-details h3 {
    margin-bottom: 10px;
    padding-bottom: 10px;
}

.contact-details p {
    margin: 5px 0;
    padding-bottom: 20px;
}

.blanksform {
    padding-bottom: 300px;
}




/* src/pages/HomePage.css */
/* App.css */

.home {
    /*background-image: url('../public/HP.background.jpg');
  */
    background-size: 100%;
    /* Ensure the image covers the entire container */
    background-position: center top 10%;
    /* Center the image */
    background-repeat: no-repeat;
    /* Prevent the image from repeating */
    min-height: 100vh;
    /* Ensure the container takes at least the full viewport height */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
}

.app-header {
    display: flex;
    align-items: center;
    padding: 20px;
    background-color: #f8f9fa;
    /* Optional background color for the header */
}

.logo {
    position: absolute;
    top: 30px;
    left: 30px;
    width: 200px;
    /* Adjust as necessary */
    height: auto;
    border-radius: 10px;
}

.navigation-buttons {
    margin-left: auto;
}

button {
    margin: 0 10px;
    padding: 10px 20px;
    background-color: #61dafb;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

button:hover {
    background-color: #21a1f1;
}

.App-header {
    text-align: center;
}

.App-title {
    font-size: 2.5em;
    background-color: #FFA500;
    /* Add your desired background color here */
    padding: 20px;
    display: block;
    margin-bottom: 40px;
    padding-bottom: 40px;
}


.App-slogan-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    /* Adjust the space between the items as needed */
}

.App-slogan {
    font-size: 1.8em;
    margin: 0;
    /* Remove default margin */
    padding: 0 10px;
    /* Add padding if needed */
    display: inline;
    /* Ensure the items are displayed inline */
}

.blank-after-slogan {
    margin-top: 300px;
}

.additional-sections {
    margin-top: 40px;
    display: block;
}

.section-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 40px;
}

.blank {
    margin-top: 100px;
}

.blanks {
    margin-bottom: 200px;
}

.text-section,
.image-section {
    margin-bottom: 40px;
    display: block;
    font-size: 1.3em;
}

.image-section img {
    display: block;
    margin: 0 auto;
    width: 500px;
    max-width: auto;
    border-radius: 10px;
    margin-bottom: 10px;
    padding-bottom: 90px;
}

.text-section p,
.image-section p {
    text-align: center;
}

.card-section {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 20px;
    background-color: #FFFFFF;
    /* Optional background color for the card section */
}

.card {
    border: 1px solid #ddd;
    border-radius: 5px;
    overflow: hidden;
    width: 300px;
    margin: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.card-image {
    width: 100%;
    height: 200px;
    object-fit: cover;
}

.card-content {
    padding: 16px;
}

.card-title {
    font-size: 1.5em;
    margin: 0 0 10px;
}

.card-description {
    font-size: 1em;
    margin: 0;
}


/* src/components/Services.css */
.services {
    background: #FFA500;
    border-radius: 5px;
    padding: 50px;
    text-align: center;
}

.services h2 {
    margin-top: 2;
}

.services ul {
    list-style: square inside;
    padding: 0;
    font-size: 1.3em;
}

.services ul li {
    margin: 40px 0;
}

.service p {
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
}

.review {
    text-align: center;
    margin: 20px 0;
    padding: 40px;
    max-width: auto;
    background-color: #f9f9f9;
    border-radius: 5px;


}

.review h2 {
    font-size: 1.3em;
    margin-bottom: 50px;
}

.review p {
    font-size: 1.3em;
    line-height: 1.5;
}

.review-picture {
    /* display: flex; */
    justify-content: 100px;
    align-items: center;
    gap: 80px;
    margin-top: auto;
    
    
    width: auto;
    max-width: 350px;
    height: auto;
    border-radius: 10px;
}

@media screen and (min-width: 1400px) {
    .review-picture {
        display: flex;
    }
}

@media screen and (max-width: 1400px) {
    .review-picture {
        margin-left: auto;
        margin-right: auto;
    }
}

.review-picture-alt {
    /* display: flex; */
    justify-content: 100px;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    gap: 80px;
    margin-top: auto;
    width: auto;
    max-width: 350px;
    height: auto;
    border-radius: 10px;
}

.blanks {
    padding-bottom: 20px;
}

.review-picture-container {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
    margin-top: 20px;
}




/* src/components/Footer.css */
.footer-container {
    display: flex;
    justify-content: space-between;
    background-color: #0000;
    width: 100%;
    padding: 0;
    margin: 0;
}

.footer-section {
    flex: 1;
    padding: 20px;
    /* Add some padding for better spacing */
}

.footer-section h3 {
    margin-top: 0;
}

.footer-section p {
    margin: 2px 0;
}

.footer-section a {
    color: #61dafb;
    text-decoration: none;
}

.footer-section a:hover {
    text-decoration: underline;
}